<section *ngIf="model && hasViewRight && !isLoading" class="d-flex flex-column du-pfa-form">
    <du-pfa-2024 [isDialog]="isDialog" [isPfa]="true" [model]="model" 
        (close)="this.close.emit()" (save)="updateDuPfaForm($event)" (invalidate)="invalidateForm()" (download)="downloadForm()">
    </du-pfa-2024>
</section>

<section *ngIf="!hasViewRight && !isLoading">
    <div class="d-flex flex-wrap">
        <div class="col-24 px-3 d-flex align-items-start">
			<div class="col px-0">
				<div class="row align-items-center">
					<div class="col d-flex">
						<div *ngIf="model" class="row align-items-center">
							<h3 class="col-auto my-0" i18n="@@MicroPofitForm$ListTitle"> {{ model.formData.display }} </h3>
                            <h5 class="col-24 mt-2"> {{ model.formData.description }} </h5>
						</div>
					</div>
                    <i *ngIf="isDialog" class="col-auto mdi-content-clear cursor-pointer font-size-1-5 mt-1 mb-1" title="Închide" (click)="this.close.emit()"></i>
                </div>
            </div>
        </div>
		<div class="divider col-24 p-0 mt-2 mb-4"></div>

        <div class="d-flex flex-wrap">
            <h5>Nu aveți drepturi de vizualizare.</h5>
        </div>
    </div>
</section>