import { Type, Exclude, Expose, classToClass } from 'class-transformer';
import { intToDate, dateToInt } from '@saliente/library';
import { SuspendareModel, SysCasaSanatate, SysDictionaryStandardFields, SysModelContract } from '../common/payroll.models';
import { round } from 'lodash-es';

export enum EmployeeStatus {
	Draft = 'Draft',
	Active = 'Activ',
	Suspended = 'Suspendat',
	Pending = 'In curs'
}

export class SysStatusAngajat {
	public id: number;
	public code: string;
	public display: string;
	public categorie?: string;

	static typeProvider() {
		return SysStatusAngajat;
	}
}

export class SysTipAngajat {
	public id: number;
	public code: string;
	public display: string;
	public cas: boolean;
	public cass: boolean;
	public impozit: boolean;
	public cam: boolean;
	public deducere: boolean;

	static typeProvider() {
		return SysTipAngajat;
	}
}

export class SysFunctieCor {
	public id: number;
	public code: string;
	public display: string;

	public get fullDisplay(): string {
        return `(${this.code}) ${this.display}`;
    }
}

export class CoasiguratModel {
	public externalId: string;
	public clientEid: string;
	public tipCoasiguratId: number;
	public numeCoasigurat: string;
	public prenumeCoasigurat: string;
	public cnp: string;
	public deLa: number;
	@Exclude()
	private _deLaEx: Date;
	@Exclude()
	public get deLaEx(): Date {
		if (!this._deLaEx && this.deLa) {
			this._deLaEx = intToDate(this.deLa);
		}
		return this._deLaEx;
	}
	public set deLaEx(value: Date) {
		if (this._deLaEx != value) {
			this._deLaEx = value;
			this.deLa = dateToInt(value);
		}
	}
	public panaLa: number;
	@Exclude()
	private _panaLaEx: Date;
	@Exclude()
	public get panaLaEx(): Date {
		if (!this._panaLaEx && this.panaLa) {
			this._panaLaEx = intToDate(this.panaLa);
		}
		return this._panaLaEx;
	}
	public set panaLaEx(value: Date) {
		if (this._panaLaEx != value) {
			this._panaLaEx = value;
			this.panaLa = dateToInt(value);
		}
	}
	@Exclude()
	public isNew: boolean; // new or update existing
	@Exclude()
	public isAdded: boolean = true; //need it when main save button (for new contract) is pressed multiple times;

	@Exclude({ toPlainOnly: true })
	@Type(SysDictionaryStandardFields.typeProvider)
	public tipCoasigurat: SysDictionaryStandardFields;

	static typeProvider() {
		return CoasiguratModel;
	}

	public clone(): CoasiguratModel {
		return classToClass(this, { ignoreDecorators: true });
	}
}

export class DeducereModel {
	public externalId: string;
	public clientEid: string;
	public persoanaInDeducere: string;
	public cnpPersoana: string;
	public tipDeducereId: string;
	public gradHandicapId: number;
	public deLa: number;
	@Exclude()
	private _deLaEx: Date;
	@Exclude()
	public get deLaEx(): Date {
		if (!this._deLaEx && this.deLa) {
			this._deLaEx = intToDate(this.deLa);
		}
		return this._deLaEx;
	}
	public set deLaEx(value: Date) {
		if (this._deLaEx != value) {
			this._deLaEx = value;
			this.deLa = dateToInt(value);
		}
	}
	public panaLa: number;
	@Exclude()
	private _panaLaEx: Date;
	@Exclude()
	public get panaLaEx(): Date {
		if (!this._panaLaEx && this.panaLa) {
			this._panaLaEx = intToDate(this.panaLa);
		}
		return this._panaLaEx;
	}
	public set panaLaEx(value: Date) {
		if (this._panaLaEx != value) {
			this._panaLaEx = value;
			this.panaLa = dateToInt(value);
		}
	}
	@Exclude()
	public isNew: boolean; // new or update existing
	@Exclude()
	public isAdded: boolean = true; //need it when main save button (for new contract) is pressed multiple times;

	@Exclude({ toPlainOnly: true })
	@Type(SysDictionaryStandardFields.typeProvider)
	public gradHandicap: SysDictionaryStandardFields;

	@Exclude({ toPlainOnly: true })
	@Type(SysDictionaryStandardFields.typeProvider)
	public tipDeducere: SysDictionaryStandardFields;

	static typeProvider() {
		return DeducereModel;
	}

	public clone(): DeducereModel {
		return classToClass(this, { ignoreDecorators: true });
	}
}

export class HandicapModel {
	public externalId: string;
	public clientEid: string;
	//public employeeEid: string;
	public tipHandicapId: number;
	public gradHandicapId: number;
	public documentEid: string;
	public deLa: number;
	@Exclude()
	private _deLaEx: Date;
	@Exclude()
	public get deLaEx(): Date {
		if (!this._deLaEx && this.deLa) {
			this._deLaEx = intToDate(this.deLa);
		}
		return this._deLaEx;
	}
	public set deLaEx(value: Date) {
		if (this._deLaEx != value) {
			this._deLaEx = value;
			this.deLa = dateToInt(value);
		}
	}
	public panaLa: number;
	@Exclude()
	private _panaLaEx: Date;
	@Exclude()
	public get panaLaEx(): Date {
		if (!this._panaLaEx && this.panaLa) {
			this._panaLaEx = intToDate(this.panaLa);
		}
		return this._panaLaEx;
	}
	public set panaLaEx(value: Date) {
		if (this._panaLaEx != value) {
			this._panaLaEx = value;
			this.panaLa = dateToInt(value);
		}
	}
	@Exclude()
	public isNew: boolean; // new or update existing
	@Exclude()
	public isAdded: boolean = true; //need it when main save button (for new contract) is pressed multiple times;

	@Exclude({ toPlainOnly: true })
	@Type(SysDictionaryStandardFields.typeProvider)
	public tipHandicap: SysDictionaryStandardFields;

	@Exclude({ toPlainOnly: true })
	@Type(SysDictionaryStandardFields.typeProvider)
	public gradHandicap: SysDictionaryStandardFields;

	static typeProvider() {
		return HandicapModel;
	}

	public clone(): HandicapModel {
		return classToClass(this, { ignoreDecorators: true });
	}
}

export class AngajatAttachmentModel {
	public fileType: string;
	public fileEid: string;
	public fileName: string;
	public fileSize: number;
	public displayOrder: number;

	@Exclude()
	public get convertedFileSize(): number {
		// 1 KB = 1024 bytes; 1 MB = 1024 KB; 1 GB = 1024 MB
		// 1mb = 1 * 1024 * 1024
		if (this.fileSize >= 1048576) { // 1024 * 1024
			return round(this.fileSize / 1048576, 2); // = MB
		}
		else if (this.fileSize >= 1024 && this.fileSize < 1048576) {
			return round(this.fileSize / 1024, 2)// = KB
		}
		else if (this.fileSize < 1024) {
			return round(this.fileSize, 2); // = B
		}
		else {
			return round(this.fileSize, 2)
		}
	}

	@Exclude()
	public get convertedFileUM(): string {
		// 1 KB = 1024 bytes; 1 MB = 1024 KB; 1 GB = 1024 MB
		// 1mb = 1 * 1024 * 1024
		if (this.fileSize >= 1048576) { // 1024 * 1024
			return "MB";
		}
		else if (this.fileSize >= 1024 && this.fileSize < 1048576) {
			return "KB";
		}
		else if (this.fileSize < 1024) {
			return "B";
		}
		else {
			return "B";
		}
	}

	
	static typeProvider() {
		return AngajatAttachmentModel;
	}
}

export class EmployeeListModel {
	public recordsCount: number;
	public first: number;
	public last: number;
	public data: EmployeeModel[];
}

export class EmployeeModel {
	@Exclude()
	public displayIndex: number;
	public externalId: string;
	public cnp: string;
	public prenume: string;
	public nume: string;
	public contractNo: string;
	public contractDate: number;
	@Exclude()
	private _contractDateEx: Date;
	@Exclude()
	public get contractDateEx(): Date {
		if (!this._contractDateEx && this.contractDate) {
			this._contractDateEx = intToDate(this.contractDate);
		}
		return this._contractDateEx;
	}
	public set contractDateEx(value: Date) {
		if (this._contractDateEx != value) {
			this._contractDateEx = value;
			this.contractDate = dateToInt(value);
		}
	}
	public siteName: string;
	public departament: string;
	public validat: boolean;

	@Exclude()
	private _fullName: string;
	@Exclude()
	public get fullName() {
		if (!this._fullName) {
			this._fullName = `${this.nume} ${this.prenume}`;
		}
		return this._fullName;
	}
	
	public tarifarIncadrare: number;
	public minimumWage: number;
	public hasMinimumWage: boolean;
	public minimumWageYearsExceeded: boolean;
	public minimumWageDate: number;
	@Exclude()
	private _minimumWageDateEx: Date;
	@Exclude()
	public get minimumWageDateEx(): Date {
		if (!this._minimumWageDateEx && this.minimumWageDate) {
			this._minimumWageDateEx = intToDate(this.minimumWageDate);
		}
		return this._minimumWageDateEx;
	}
	public set minimumWageDateEx(value: Date) {
		if (this._minimumWageDateEx != value) {
			this._minimumWageDateEx = value;
			this.minimumWageDate = dateToInt(value);
		}
	}

	@Type(SysModelContract.typeProvider)
	public modelContract: SysModelContract;

	@Type(SysStatusAngajat.typeProvider)
	public statusAngajat: SysStatusAngajat;

	static typeProvider() {
		return EmployeeModel;
	}
}

export class EmployeeDetailsModel {
	@Exclude()
	public displayIndex: number;
	// @Exclude()
	// public prevEmployeeEid: string;
	// @Exclude()
	// public nextEmployeeEid: string;

	public initialaTata: string;
	public dataNasterii: number;
	@Exclude()
	private _dataNasteriiEx: Date;
	@Exclude()
	get dataNasteriiEx() {
		if (!this._dataNasteriiEx && this.dataNasterii) {
			this._dataNasteriiEx = intToDate(this.dataNasterii);
		}
		return this._dataNasteriiEx;
	}
	set dataNasteriiEx(value: Date) {
		if (this._dataNasteriiEx != value) {
			this._dataNasteriiEx = value;
			this.dataNasterii = dateToInt(value);
		}
	}
	public cis: string;
	public email: string;
	public telefon: string;
	public adresa: string;
	public judetCod: string;
	public judetNume: string;
	public orasNume: string;
	public taraCod: string = "RO";
	public taraNume: string = "România";
	public banca: string;
	public iban: string;
	public cetatenieCod: string;
	public cetatenieNume: string;
	public serie: string;
	public numar: string;
	public eliberatDe: string;
	public valabilDeLa: number;
	@Exclude()
	private _valabilDeLaEx: Date;
	@Exclude()
	get valabilDeLaEx() {
		if (!this._valabilDeLaEx && this.valabilDeLa) {
			this._valabilDeLaEx = intToDate(this.valabilDeLa);
		}
		return this._valabilDeLaEx;
	}
	set valabilDeLaEx(value: Date) {
		if (this._valabilDeLaEx != value) {
			this._valabilDeLaEx = value;
			this.valabilDeLa = dateToInt(value);
		}
	}
	public valabilPanaLa: number;
	@Exclude()
	private _valabilPanaLaEx: Date;
	@Exclude()
	get valabilPanaLaEx() {
		if (!this._valabilPanaLaEx && this.valabilPanaLa) {
			this._valabilPanaLaEx = intToDate(this.valabilPanaLa);
		}
		return this._valabilPanaLaEx;
	}
	set valabilPanaLaEx(value: Date) {
		if (this._valabilPanaLaEx != value) {
			this._valabilPanaLaEx = value;
			this.valabilPanaLa = dateToInt(value);
		}
	}
	public dataPlecarii: number;
	@Exclude()
	private _dataPlecariiEx: Date;
	@Exclude()
	public get dataPlecariiEx(): Date {
		if (!this._dataPlecariiEx && this.dataPlecarii) {
			this._dataPlecariiEx = intToDate(this.dataPlecarii);
		}
		return this._dataPlecariiEx;
	}
	public set dataPlecariiEx(value: Date) {
		if (this._dataPlecariiEx != value) {
			this._dataPlecariiEx = value;
			this.dataPlecarii = dateToInt(value);
		}
	}
	public contractNo: string;
	public contractDate: number;
	@Exclude()
	private _contractDateEx: Date;
	@Exclude()
	public get contractDateEx(): Date {
		if (!this._contractDateEx && this.contractDate) {
			this._contractDateEx = intToDate(this.contractDate);
		}
		return this._contractDateEx;
	}
	public set contractDateEx(value: Date) {
		if (this._contractDateEx != value) {
			this._contractDateEx = value;
			this.contractDate = dateToInt(value);
		}

		// let nextDay = new Date(value);
		// nextDay.setDate(value.getDate() + 1);
		// this._minDataAngajariiEx = nextDay;

	}
	public perioadaProba: number;
	public contractDocumentEid: string;
	public preavizDemisie: number;
	public preavizConcediere: number;
	public dataPlataAvans: number;
	@Exclude()
	private _dataPlataAvansEx: Date;
	@Exclude()
	public get dataPlataAvansEx(): Date {
		if (!this._dataPlataAvansEx && this.dataPlataAvans) {
			this._dataPlataAvansEx = intToDate(this.dataPlataAvans);
		}
		return this._dataPlataAvansEx;
	}
	public set dataPlataAvansEx(value: Date) {
		if (this._dataPlataAvansEx != value) {
			this._dataPlataAvansEx = value;
			this.dataPlataAvans = dateToInt(value);
		}
	}
	public dataPlataLichidare: number;
	@Exclude()
	private _dataPlataLichidareEx: Date;
	@Exclude()
	public get dataPlataLichidareEx(): Date {
		if (!this._dataPlataLichidareEx && this.dataPlataLichidare) {
			this._dataPlataLichidareEx = intToDate(this.dataPlataLichidare);
		}
		return this._dataPlataLichidareEx;
	}
	public set dataPlataLichidareEx(value: Date) {
		if (this._dataPlataLichidareEx != value) {
			this._dataPlataLichidareEx = value;
			this.dataPlataLichidare = dateToInt(value);
		}
	}
	public obiectContract: string;
	public scurtaCaracterizarePost: string;
	public criteriiEvaluare: string;
	public tipDocumentPersonalId: number;
	public nume: string;
	public prenume: string;
	public cnp: string;
	public dataAngajarii: number;
	@Exclude()
	private _dataAngajariiEx: Date;
	@Exclude()
	public get dataAngajariiEx(): Date {
		if (!this._dataAngajariiEx && this.dataAngajarii) {
			this._dataAngajariiEx = intToDate(this.dataAngajarii);
		}
		return this._dataAngajariiEx;
	}
	public set dataAngajariiEx(value: Date) {
		if (this._dataAngajariiEx != value) {
			this._dataAngajariiEx = value;
			this.dataAngajarii = dateToInt(value);
		}
	}
	@Exclude()
	public get minDataAngajariiEx(): Date {
		if (this.contractDate && this.modelContract && this.modelContract.beneficiiTipSalariat) {
			let nextDay = new Date(this._contractDateEx);
			nextDay.setDate(this._contractDateEx.getDate() + 1);

			return nextDay;
		}
		else {
			return this._contractDateEx;
		}
	}
	public tarifarIncadrare: number;
	public functieInterna: string;
	public siteEid: string;
	public siteName: string;
	public numarOre: number;
	public dataTerminarii: number;
	@Exclude()
	private _dataTerminariiEx: Date;
	@Exclude()
	public get dataTerminariiEx(): Date {
		if (!this._dataTerminariiEx && this.dataTerminarii) {
			this._dataTerminariiEx = intToDate(this.dataTerminarii);
		}
		return this._dataTerminariiEx;
	}
	public set dataTerminariiEx(value: Date) {
		if (this._dataTerminariiEx != value) {
			this._dataTerminariiEx = value;
			this.dataTerminarii = dateToInt(value);
		}
	}
	public functieBaza: boolean = true;
	public sporWeekend: boolean;
	public departament: string;
	public concediuOdihna: number;
	public concediuOdihnaSuplimentar: number;
	public scutitImpozitIt: boolean;
	public pensionar: boolean;
	public sexId: number;
	public casaSanatateId: number;
	public tipNormaId: number;
	public repartizareTipMuncaId: number;
	public durataContractId: number;
	public functieCorId: number;
	public conditiiMuncaId: number;
	public statusAngajatId: number;
	public modelContractId: number;
	public tipAngajatId: number;
	public tipOreId: number;
	public tipSporWeekendId: number;
	public dataAsigurat: number;
	@Exclude()
	private _dataAsiguratEx: Date;
	@Exclude()
	public get dataAsiguratEx(): Date {
		if (!this._dataAsiguratEx && this.dataAsigurat) {
			this._dataAsiguratEx = intToDate(this.dataAsigurat);
		}
		return this._dataAsiguratEx;
	}
	public set dataAsiguratEx(value: Date) {
		if (this._dataAsiguratEx != value) {
			this._dataAsiguratEx = value;
			this.dataAsigurat = dateToInt(value);
		}
	}

	public deducereOug67: boolean;
	public oug16: boolean;
	public lunaPlataCenzor: number;
	public valoareSporWeekend: number;
	public copiiDeducereOug16100ron: number;
	public deducereOug16200ron: boolean;
	public pilonIiOptional: boolean = false;

	@Exclude({ toPlainOnly: true })
	public externalId: string;
	@Exclude({ toPlainOnly: true })
	public clientEid: string;
	@Exclude({ toPlainOnly: true })
	public validat: boolean;

	@Exclude({ toPlainOnly: true })
	@Type(SysDictionaryStandardFields.typeProvider)
	public sex: SysDictionaryStandardFields;

	@Exclude({ toPlainOnly: true })
	@Type(SysCasaSanatate.typeProvider)
	public casaSanatate: SysCasaSanatate;

	@Exclude({ toPlainOnly: true })
	@Type(SysDictionaryStandardFields.typeProvider)
	public tipDocumentPersonal: SysDictionaryStandardFields;

	@Exclude({ toPlainOnly: true })
	@Type(SysDictionaryStandardFields.typeProvider)
	public tipNorma: SysDictionaryStandardFields;

	@Exclude({ toPlainOnly: true })
	@Type(SysDictionaryStandardFields.typeProvider)
	public repartizareTipMunca: SysDictionaryStandardFields;

	@Exclude({ toPlainOnly: true })
	@Type(SysDictionaryStandardFields.typeProvider)
	public durataContract: SysDictionaryStandardFields;

	@Exclude({ toPlainOnly: true })
	@Type(SysDictionaryStandardFields.typeProvider)
	public functieCor: SysDictionaryStandardFields;

	@Exclude({ toPlainOnly: true })
	@Type(SysDictionaryStandardFields.typeProvider)
	public conditiiMunca: SysDictionaryStandardFields;

	@Exclude({ toPlainOnly: true })
	@Type(SysStatusAngajat.typeProvider)
	public statusAngajat: SysStatusAngajat;

	@Exclude({ toPlainOnly: true })
	@Type(SysModelContract.typeProvider)
	public modelContract: SysModelContract;

	@Exclude({ toPlainOnly: true })
	@Type(SysTipAngajat.typeProvider)
	public tipAngajat: SysTipAngajat;

	@Exclude({ toPlainOnly: true })
	@Type(SysDictionaryStandardFields.typeProvider)
	public tipOre: SysDictionaryStandardFields;

	@Exclude({ toPlainOnly: true })
	@Type(SysDictionaryStandardFields.typeProvider)
	public tipSporWeekend: SysDictionaryStandardFields;

	@Exclude({ toPlainOnly: true })
	@Type(CoasiguratModel.typeProvider)
	public coasigurati: CoasiguratModel[];

	@Exclude({ toPlainOnly: true })
	@Type(DeducereModel.typeProvider)
	public deduceri: DeducereModel[];

	@Exclude({ toPlainOnly: true })
	@Type(HandicapModel.typeProvider)
	public handicapuri: HandicapModel[];

	@Exclude({ toPlainOnly: true })
	@Type(SuspendareModel.typeProvider)
	public suspendari: SuspendareModel[];

	@Type(AngajatAttachmentModel.typeProvider)
	public angajatAttachments: AngajatAttachmentModel[] = [];
}

export class ChangeEmployeeSalaryModel {
	public numar: string;
	public tarifarIncadrare: number;
	public dataModificare: number;
	@Exclude()
	private _dataModificareEx: Date;
	@Exclude()
	public get dataModificareEx(): Date {
		if (!this._dataModificareEx && this.dataModificare) {
			this._dataModificareEx = intToDate(this.dataModificare);
		}
		return this._dataModificareEx;
	}
	public set dataModificareEx(value: Date) {
		if (this._dataModificareEx != value) {
			this._dataModificareEx = value;
			this.dataModificare = dateToInt(value);
		}
	}
}

export class ChangeEmployeeMinimumSalaryModel {
	public employeeEid: string;
	public numar: string;
	public tarifarIncadrare: number;
	public dataModificare: number;
	@Exclude()
	private _dataModificareEx: Date;
	@Exclude()
	public get dataModificareEx(): Date {
		if (!this._dataModificareEx && this.dataModificare) {
			this._dataModificareEx = intToDate(this.dataModificare);
		}
		return this._dataModificareEx;
	}
	public set dataModificareEx(value: Date) {
		if (this._dataModificareEx != value) {
			this._dataModificareEx = value;
			this.dataModificare = dateToInt(value);
		}
	}

	@Exclude()
	public minimumWage: number;
	@Exclude()
	public hasMinimumWage: boolean;
	@Exclude()
	public minimumWageYearsExceeded: boolean;
}

export class EmployeeTerminationModel {
	public numar: number;
	public nrZileCoNeefectuat: number;
	public dataPlecarii: number
	@Exclude()
	private _dataPlecariiEx: Date;
	@Exclude()
	public get dataPlecariiEx(): Date {
		if (!this._dataPlecariiEx && this.dataPlecarii) {
			this._dataPlecariiEx = intToDate(this.dataPlecarii);
		}
		return this._dataPlecariiEx;
	}
	public set dataPlecariiEx(value: Date) {
		if (this._dataPlecariiEx != value) {
			this._dataPlecariiEx = value;
			this.dataPlecarii = dateToInt(value);
		}
	}
}