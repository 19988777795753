import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { MicroProfitFormModel } from "../client-forms.models";
import { ClientFormsService } from "../client-forms.service";
import { AuthService, PredefinedUserZone } from "@saliente/common-auth";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { switchMap } from "rxjs/operators";

@Component({
	selector: 'mp2025-p2m-form',
	templateUrl: 'mp2025-p2m-form.component.html',
    styleUrls: ['mp2025-p2m-form.component.scss']

})
export class MP2025P2MFormComponent implements OnInit, OnDestroy {
    @Input()
    public isDialog: boolean;
    @Input()
    public clientEidParam: string;
    @Input()
    public instanceParam: string;

    @Output() public close = new EventEmitter();

    //subscriptions
	private dataSubscription: Subscription;
    
    //models
    public formModel: MicroProfitFormModel;

    public clientEid: string;
    public instance: string;

    //booleans
    public isReadOnly: boolean;
    public hasViewRight: boolean;
    public isLoading: boolean;
    public showConfirmationDialog: boolean = false;

    get are1FTEDisabled(): boolean {
        return this.formModel.data.doresteMicro == null || !this.formModel.data.doresteMicro;
    }

    get caSub500kDisabled(): boolean {
        return this.formModel.data.are1FTE == null || !this.formModel.data.are1FTE;
    }

    get are253MicroDisabled(): boolean {
        return this.formModel.data.caSub500k == null || !this.formModel.data.caSub500k;
    }

    get pastreazaFirmaMicroDisabled(): boolean {
        return this.formModel.data.are253Micro == null || !this.formModel.data.are253Micro;
    }

    get saveDisabled(): boolean {
        return !this.isConclusionSet;
    }

    get isConclusionSet() : boolean {
        return ((this.formModel.data.ramaneMicro || this.formModel.data.devineMicro) && (this.formModel.data.micro3p || this.formModel.data.micro1p))
            || this.formModel.data.devineProfit  || this.formModel.data.ramaneProfit;
    }

    constructor(private clientFormsService: ClientFormsService, private authService: AuthService, private route: ActivatedRoute, private toastr: ToastrService,
        private cdr: ChangeDetectorRef) {}
        
    ngOnInit(): void {
        this.isLoading = true;
        this.dataSubscription = this.route.paramMap
            .pipe(
                switchMap((params: ParamMap) => {
                    this.clientEid = this.isDialog ? this.clientEidParam : params.get('id');
                    this.instance = "mp2025-2025-01-p2m";

                    return this.clientFormsService.getMicroProfitData(this.clientEid, this.instance);
                })
		    )
			.subscribe(async (formData: MicroProfitFormModel) => {
                if (formData) {
                    this.isReadOnly = this.authService.selectedZoneCode == PredefinedUserZone.Expert || formData.formData.status == 'Submitted';

                    this.hasViewRight = this.authService.isInRole('EXPERT') || this.authService.isInRole('ZEUS') 
                        || this.authService.isInRole(formData.formData.displayRole);

                    if (this.hasViewRight) {
                        this.formModel = formData;

                        this.initializeConclusion();

                        if (this.formModel.formData.status != 'Submitted') {
                            this.computeBooleans();
                        }

                        this.getFormConclusion();
                    }
                }
                else {
                    this.formModel = null;
                }

                this.isLoading = false;

                setTimeout(() => {
                    this.cdr.detectChanges();
                }, 200);
		});
    }

    ngOnDestroy(): void {
        if (this.dataSubscription) { this.dataSubscription.unsubscribe(); this.dataSubscription = null; }
    }

    public onAre1FTEHelp() {
        let url = "https://app.keez.ro/help/client/faq/modificari_fiscale_2023.html#conditie-micro-angajat";
		window.open(url, '_blank', 'noopener noreferrer');
    }

    public onCaSub500k() {
        let url = "https://app.keez.ro/help/client/faq/modificari_fiscale_2024.html#intreprinderi-legate";
		window.open(url, '_blank', 'noopener noreferrer');
    }

    public onAre253Micro() {
        let url = "https://app.keez.ro/help/client/faq/modificari_fiscale_2023.html#conditie-micro-actionari-cu-25-din-partile-sociale-la-3-firme";
		window.open(url, '_blank', 'noopener noreferrer');
    }

    public onRamaneMicroHelp() {
        let url = this.formModel.data.ramaneMicro 
            ? "https://app.keez.ro/help/client/antreprenor/taxe_impozite.html#impozitul-pe-venit"
            : "https://app.keez.ro/help/client/antreprenor/taxe_impozite.html#impozitul-pe-profit";

        window.open(url, '_blank', 'noopener noreferrer');   
    }

    public computeBooleans() {
        this.onDoresteMicroChange();
        this.onAre1FTEChange();
        this.onAre253MicroChange();
        this.onPastreazaFirmaMicroChange();
    }

    public initializeConclusion() {
        this.formModel.data.ramaneMicro = this.formModel.data.ramaneMicro == null ? false : this.formModel.data.ramaneMicro;
        this.formModel.data.devineProfit = this.formModel.data.devineProfit == null ? false : this.formModel.data.devineProfit;
        this.formModel.data.devineMicro = this.formModel.data.devineMicro == null ? false : this.formModel.data.devineMicro;
        this.formModel.data.ramaneProfit = this.formModel.data.ramaneProfit == null ? false : this.formModel.data.ramaneProfit;
    }

    public noConclusion() {
        this.formModel.data.ramaneMicro = false;
        this.formModel.data.devineProfit = false;
        this.formModel.data.devineMicro = false;
        this.formModel.data.ramaneProfit = false;
    }

    /*public ramaneMicro() {
        this.formModel.data.ramaneMicro = true;
        this.formModel.data.devineProfit = false;
        this.formModel.data.devineMicro = false;
        this.formModel.data.ramaneProfit = false;
    }*/

    public devineMicro() {
        this.formModel.data.ramaneMicro = false;
        this.formModel.data.devineProfit = false;
        this.formModel.data.devineMicro = true;
        this.formModel.data.ramaneProfit = false;

        this.formModel.data.micro1p = true;
        this.formModel.data.micro3p = false;
    }

    public ramaneProfit() {
        this.formModel.data.ramaneMicro = false;
        this.formModel.data.devineProfit = false;
        this.formModel.data.devineMicro = false;
        this.formModel.data.ramaneProfit = true;

        this.formModel.data.micro1p = null;
        this.formModel.data.micro3p = null;
    }

    /*public devineProfit() {
        this.formModel.data.ramaneMicro = false;
        this.formModel.data.devineProfit = true;
        this.formModel.data.devineMicro = false;
        this.formModel.data.ramaneProfit = false;
    }*/

    public resetAnswersFromQuestionBelow(fromQuestionBelow: string) {
        if (fromQuestionBelow == "DoresteMicro") {
            this.formModel.data.are1FTE = null;
            this.formModel.data.caSub500k = null;
            this.formModel.data.are253Micro = null;
            this.formModel.data.pastreazaFirmaMicro = null;
            this.formModel.data.micro1p = null;
            this.formModel.data.micro3p = null;
        }
        if (fromQuestionBelow == "Are1FTE") {
            this.formModel.data.caSub500k = null;
            this.formModel.data.are253Micro = null;
            this.formModel.data.pastreazaFirmaMicro = null;
            this.formModel.data.micro1p = null;
            this.formModel.data.micro3p = null;
        }
        if (fromQuestionBelow == "AreConsultanta80") {
            this.formModel.data.caSub500k = null;
            this.formModel.data.are253Micro = null;
            this.formModel.data.pastreazaFirmaMicro = null;
            this.formModel.data.micro1p = null;
            this.formModel.data.micro3p = null;
        }
        if (fromQuestionBelow == "CaSub500k") {
            this.formModel.data.are253Micro = null;
            this.formModel.data.pastreazaFirmaMicro = null;
            this.formModel.data.micro1p = null;
            this.formModel.data.micro3p = null;
        }
        if (fromQuestionBelow == "Are253Micro") {
            this.formModel.data.pastreazaFirmaMicro = null;
            this.formModel.data.micro1p = null;
            this.formModel.data.micro3p = null;
        }
        if (fromQuestionBelow == "PastreazaFirmaMicro") {
            this.formModel.data.micro1p = null;
            this.formModel.data.micro3p = null;
        }
    }

    public onDoresteMicroChange() {
        if (this.formModel.data.doresteMicro == null) 
            return;

        if (this.formModel.data.doresteMicro) { // answer: yes - no conclusion yet, need to answer the following questions
           this.noConclusion();
        }
        else { // answer: no - there is no need for the other questions; conclusion: ramaneProfit
            this.ramaneProfit();

            this.resetAnswersFromQuestionBelow("DoresteMicro");
        }
    }

    public onAre1FTEChange() {
        if (this.formModel.data.are1FTE == null) 
            return;

        if (this.formModel.data.are1FTE) { // answer: yes - no conclusion yet, need to answer the following questions
           this.noConclusion();
        }
        else { // answer: no - there is no need for the other questions: ramaneProfit
            this.ramaneProfit();

            this.resetAnswersFromQuestionBelow("Are1FTE");
        }
    }

    public onCaSub500kChange() { 
        if (this.formModel.data.caSub500k == null) 
            return;

        if (this.formModel.data.caSub500k) { // answer: yes - no conclusion yet, need to answer the following questions
            this.noConclusion();
        }
        else { // answer: no - there is no need for the following questions: ramaneProfit
            this.ramaneProfit();
            
            this.resetAnswersFromQuestionBelow("CaSub500k");
        }
    }

    public onAre253MicroChange() {
        if (this.formModel.data.are253Micro == null) 
            return;

        if (this.formModel.data.are253Micro) { // answer: yes - no conclusion yet, need to answer the following questions
            this.noConclusion();
        }
        else { // answer: no - there is no need for the following questions: devineMicro
            this.devineMicro();

            //this.resetAnswersFromQuestionBelow("Are253Micro");
        }
    }

    public onPastreazaFirmaMicroChange() {
        if (this.formModel.data.pastreazaFirmaMicro == null) 
            return;

        if (this.formModel.data.pastreazaFirmaMicro) { // answer: yes - devineMicro
            this.devineMicro();
        }
        else { // answer: no - ramaneProfit
            this.ramaneProfit();

            this.resetAnswersFromQuestionBelow("PastreazaFirmaMicro");
        }
    }

    public getFormConclusion() {
        if (this.formModel.data.devineMicro) {
            return 'Firma va deveni Microîntreprindere - plătitoare de impozit pe venit 1%'
        }
        else if (this.formModel.data.ramaneProfit) {
            return 'Firma va ramane plătitoare de impozit pe profit.';
        }
        else {
            return null;
        }
    }

    public openConfirmationDialog() {
        this.showConfirmationDialog = true;
    }

    public closeConfirmationDialog() {
        this.showConfirmationDialog = false;
    }

    public async saveMicroProfitForm() {
        let result = await this.clientFormsService.updateClientFormMicroProfitData(this.clientEid, this.instance, this.formModel.data);
        if (result) {
            if (!this.isDialog) {
                this.formModel.formData.status = "Submitted";
                this.isReadOnly = this.authService.selectedZoneCode == PredefinedUserZone.Expert || this.formModel.formData.status == 'Submitted';
            }
            
            this.cancelMicroProfitForm();
            this.toastr.success("Formularul a fost salvat cu succes!");
        }
    }

    public cancelMicroProfitForm() {
        this.closeConfirmationDialog();

        if (this.isDialog) {
            this.close.emit();
        }
    }
}