import { Injectable, EventEmitter } from '@angular/core';

import { Observable, BehaviorSubject, of, Subscription, } from 'rxjs';
import { first, switchMap, map, catchError, take, } from 'rxjs/operators';
import gql from 'graphql-tag';

import { classToPlain, plainToClass } from 'class-transformer';

import { KMutationOptions, RestService } from '../general/rest.service';
import { AuthService } from '../auth/auth.service';
import { ConfigService } from '../general/config.service';

import { PredefinedUserZone, } from '../auth/auth.models';
import { ChatModel, ChatMessageChunkModel, ChatMessageModel, ChatTypeModel, ClientChatInputModel, ClientChatCommentInputModel, } from './chat.models';
import { AcceptMeetingGQL, CancelMeetingGQL, GetMeetingRequestGQL, GetMeetingSlotsGQL, JoinMeetingGQL, ProposeNewMeetingScheduleGQL, RequestMeetingGQL, GetMainClientExpertInfoGQL } from './meetings/adapters/meetings-api.generated';
import { ExpertInfoPersonModel, MeetingRequestDataHistoryModel, MeetingRequestDataModel, MeetingRequestModel, MeetingSlotsExpertListModel, MeetingSlotsListModel, MeetingTimeSlotsModel } from './meetings/meetings.model';
import { Notifications_MeetingRequestRepresentationInput } from 'src/graphql';

let SockJS: any = require('./.dist/sockjs');
let Stomp: any = require('./.dist/stomp').Stomp;

const chatFields = `
	chatStatus
	chatTypeCode
	chatTypeExternalId
	chatTypeName
	clientExternalId
	clientName
	firstUnseenMessage
	firstUnansweredMessage
	lastMessageDelivered
	unseenMessages
	unansweredMessages
	clientInSpecialCare
`;
const chatTypeFields = `
	code
	externalId
	name
`;
const allChatsQueryText = gql`
query allChats{
  notifications {
    chats: getChatsUsingGET {
		${chatFields}
    }
    chatTypes: getChatTypesUsingGET {
		${chatTypeFields}
    }
  }
}`;

const clientChatsQueryText = gql`
query clientChats($clientEid: String!){
  notifications {
    chats: getChatsByClientUsingGET(clientExternalId: $clientEid) {
		${chatFields}
    }
    chatTypes: getChatTypesUsingGET {
		${chatTypeFields}
    }
  }
}`;

const messageChunkFields = `
	chatTypeEid
	chunkLength
	clientEid
	createdDate
	displayMessageId
	eid
	firstMessageId
	lastMessageId
	messages{
		attachedDocuments{
			documentEid
			documentFileName
		}
		authorEid
		authorName
		authorIsExpert
		authorIsKeez
		content
		datePosted
		links{
			displayName
			url
		}
		messageId
		replyTo{
			authorEid
			authorName
			content
			datePosted
			messageId
		}
	}
	nextChunkEid
	prevChunkEid
`;

const firstUnseenChunkQueryText = gql`
query firstUnseenChunk($clientEid: String!, $chatTypeEid: String!){
  notifications {
    chunk: getFirstUnseenChatMessagesUsingGET(clientExternalId: $clientEid, chatTypeExternalId:$chatTypeEid) {
		${messageChunkFields}
    }
  }
}`;

const messagesChunkQueryText = gql`
query messagesChunk($clientEid: String!, $chatTypeEid: String!, $chunkEid: String!){
  notifications {
    chunk: getChatChunkUsingGET(clientExternalId: $clientEid, chatTypeExternalId:$chatTypeEid, chunkExternalId:$chunkEid) {
		${messageChunkFields}
    }
  }
}`;

const prevMessagesChunkQueryText = gql`
query prevMessagesChunk($clientEid: String!, $chatTypeEid: String!, $chunkEid: String!){
  notifications {
    chunk: getPrevChatChunkUsingGET(clientExternalId: $clientEid, chatTypeExternalId:$chatTypeEid, chunkExternalId:$chunkEid) {
		${messageChunkFields}
    }
  }
}`;

const nextMessagesChunkQueryText = gql`
query nextMessagesChunk($clientEid: String!, $chatTypeEid: String!, $chunkEid: String!){
  notifications {
    chunk: getNextChatChunkUsingGET(clientExternalId: $clientEid, chatTypeExternalId:$chatTypeEid, chunkExternalId:$chunkEid) {
		${messageChunkFields}
    }
  }
}`;

const moveMessageMutationText = gql`
mutation moveMessage($clientEid: String!, $chatTypeEid: String!, $messageId: Int!, $destChatTypeEid: String!){
  notifications {
    moveChatMessageUsingPOST(clientExternalId: $clientEid, chatTypeExternalId:$chatTypeEid, messageId:$messageId, destChatTypeExternalId:$destChatTypeEid) {
		Message
    }
  }
}`;

const markClientChatUrgent = gql`
mutation markClientChatUrgent($clientChatInput: notifications_ClientChatIdRepresentationInput){
	notifications {
		markClientChatUrgentUsingPOST(clientChat: $clientChatInput) {
			Message
	}
  }
}`;

const keepClientChatImportant = gql`
mutation keepClientChatImportant($clientChatInput: notifications_ClientChatIdRepresentationInput){
	notifications {
		keepClientChatImportantUsingPOST(clientChat: $clientChatInput) {
			Message
	}
  }
}`;

const markClientChatAnswered = gql`
mutation markClientChatAnswered($clientChatCommentInput: notifications_ClientChatCommentRepresentationInput){
	notifications {
		markClientChatAnsweredUsingPOST(clientChat: $clientChatCommentInput) {
			Message
	}
  }
}`;

const addChatMessageMutationText = gql`
mutation addChatMessage($clientExternalId: String!, $chatTypeExternalId: String!, $message: notifications_ChatMessageRepresentationInput!) {
	notifications {
		addChatMessageUsingPOST(clientExternalId: $clientExternalId, chatTypeExternalId: $chatTypeExternalId, message: $message) {
			Message # empty
		}
	}
}`;

@Injectable()
export class ChatService {
	private correlationId: number = 0;
	private correlations: any = {};

	private _prevChunkEid: string;
	public get prevChunkEid(): string {
		return this._prevChunkEid;
	}
	private _nextChunkEid: string;
	public get nextChunkEid(): string {
		return this._nextChunkEid;
	}
	private _displayMessageId: number;
	public get displayMessageId(): number {
		return this._displayMessageId;
	}

	public chats: BehaviorSubject<ChatModel[]>;
	public chatTypes: BehaviorSubject<ChatTypeModel[]>;
	public messages: BehaviorSubject<ChatMessageModel[]>;

	private lastChat: ChatModel = null;
	private pendingMessage: string = null;
	private currentChat: ChatModel = null;

	public $$dataChanged: EventEmitter<any> = new EventEmitter<any>();
	//public $$chatsChanged: EventEmitter<any> = new EventEmitter<any>();

	public get isInAnyRoom(): boolean {
		return !!this.currentChat;
	}

	public get currentChatRoom(): ChatModel {
		return this.currentChat;
	}

	public get isFINChatRoom(): boolean {
		return this.currentChat.chatTypeCode == "FIN";
	}

	public get unseenMessages(): number {
		// return this.chats.value.reduce((accumulator, currentValue) => accumulator + ((currentValue.chatStatus > 0) ? 1 : 0), 0);
		return this.chats.value.reduce((accumulator, currentValue) => accumulator + (currentValue.unseenMessages || 0), 0);
	}

	public get unansweredMessages(): number {
		return this.chats.value.reduce((accumulator, currentValue) => accumulator + ((currentValue.chatStatus > 0) ? 1 : 0), 0);
	}

	constructor(private authService: AuthService, private restService: RestService, private configService: ConfigService,) {
		this.chats = new BehaviorSubject<ChatModel[]>([]);
		this.chatTypes = new BehaviorSubject<ChatTypeModel[]>([]);
		this.messages = new BehaviorSubject<ChatMessageModel[]>([]);

		this.connect();
		this.initializeChats(false, false);
		setInterval(() => {
			this.initializeChats(true, false);
		}, 300000); //30000
	}

	private sortChats1(chats: ChatModel[]) {
		chats.sort((a: ChatModel, b: ChatModel) => {
			let aTime = a.lastMessageDeliveredTime(),
				bTime = b.lastMessageDeliveredTime();
			if (aTime == bTime) {
				let result = a.clientName.localeCompare(b.clientName);
				if (!result) {
					return a.chatTypeName.localeCompare(b.chatTypeName);
				}
				return result;
			}
			//sort desc
			return bTime - aTime;
		});
	}

	public sortChats(chats: ChatModel[]) {
		let now = new Date();
		let d2k = new Date(2000, 1, 1);

		chats.sort((a: ChatModel, b: ChatModel) => {
			// sort by clientInSpecialCare
			// let aSpecialCare = a.clientInSpecialCare ? 1 : 0;
			// let bSpecialCare = b.clientInSpecialCare ? 1 : 0;

			let aStatus = a.chatStatus == null ? 0 : a.chatStatus;
			let bStatus = b.chatStatus == null ? 0 : b.chatStatus;

			aStatus = a.clientInSpecialCare && a.chatStatus >= 1 ? 4 : a.chatStatus;
			bStatus = b.clientInSpecialCare && b.chatStatus >= 1 ? 4 : b.chatStatus;

			// sort by first unanswered
			if (aStatus == bStatus) {
				let aFirstUnanswered = now.getTime() - (a.firstUnansweredMessage == null ? now.getTime() : a.firstUnansweredMessage.getTime());
				let bFirstUnanswered = now.getTime() - (b.firstUnansweredMessage == null ? now.getTime() : b.firstUnansweredMessage.getTime());

				// sort by first unseen
				if (aFirstUnanswered == bFirstUnanswered) {
					let aFirstUnseen = (a.firstUnseenMessage == null || a.unseenMessages == 0) ? d2k.getTime() : a.lastMessageDeliveredTime();
					let bFirstUnseen = (b.firstUnseenMessage == null || b.unseenMessages == 0) ? d2k.getTime() : b.lastMessageDeliveredTime();
					// let aFirstUnseen = now.getTime() - ((a.firstUnseenMessage == null || a.unseenMessages == 0) ? now.getTime() : a.lastMessageDeliveredTime());
					// let bFirstUnseen = now.getTime() - ((b.firstUnseenMessage == null || b.unseenMessages == 0) ? now.getTime() : b.lastMessageDeliveredTime());

					// sort by unseen message
					if (aFirstUnseen == bFirstUnseen) {
						let aUnseenMsg = a.unseenMessages;
						let bUnseenMsg = b.unseenMessages;

						// sort by clientName & chatTypeName
						if (aUnseenMsg == bUnseenMsg) {
							let result = a.clientName.localeCompare(b.clientName);
							if (!result) {
								return a.chatTypeName.localeCompare(b.chatTypeName);
							}
							return result;
						}

						// sort desc
						return bUnseenMsg - aUnseenMsg;
					}

					//sort desc
					return bFirstUnseen - aFirstUnseen;
				}

				//sort desc
				return bFirstUnanswered - aFirstUnanswered;
			}
			//sort desc
			return bStatus - aStatus;
		});
	}

	public subscription: Subscription;
	public initializeChats(fromRefresh: boolean, showSpinner: boolean = false) {
		if (this.subscription) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}

		this.subscription = this.authService.getSelectedZoneCode()
			.pipe(
				switchMap((zone: string) => {
					switch (zone) {
						case PredefinedUserZone.Subcontractor:
							return of([]);
						case PredefinedUserZone.Administrator:
							return this.clientChatStream(showSpinner);
						case PredefinedUserZone.None:
							return of([]);
						default:
							return this.allChatStream(showSpinner);
					}
				})
			)
			.subscribe((chats: any) => {
				if (!fromRefresh) {
					this.pendingMessage = null;
					this.lastChat = null;
					this.sortChats(chats);
					this.announceMe();
					this.chats.next(chats);
				}
				else {
					//this.sortChats(chats);
					this.chats.next(chats);
				}
			});
	}

	public clearChats() {
		this.pendingMessage = null;
		this.lastChat = null;
		this.chats.next([]);
		this.messages.next([]);
	}

	private allChatStream(showSpinner: boolean): Observable<ChatModel[]> {
		return this.restService
			.query(
				{
					query: allChatsQueryText,
					fetchPolicy: 'network-only'
				}, { spinner: showSpinner, error: false }
			)
			.pipe(
				catchError((error) => {
					return of(null);
				}),
				map((response: any) => {
					if (response) {
						this.chatTypes.next(plainToClass<ChatTypeModel, object>(ChatTypeModel, response.data.notifications.chatTypes));
						return plainToClass<ChatModel, object>(ChatModel, response.data.notifications.chats);
					}
					return [];
				})
			);
	}

	private clientChatStream(showSpinner: boolean): Observable<ChatModel[]> {
		return this.authService.getSelectedCompanyId()
			.pipe(
				switchMap((clientEid: string) => {
					//this.clearChats();

					return this.restService
						.query(
							{
								query: clientChatsQueryText,
								variables: {
									clientEid
								},
								fetchPolicy: 'network-only'
							}, { spinner: showSpinner, error: false }
						)
						.pipe(
							catchError((error) => {
								return null;
							}),
							map((response: any) => {
								if (response) {
									this.chatTypes.next(plainToClass<ChatTypeModel, object>(ChatTypeModel, response.data.notifications.chatTypes));
									return plainToClass<ChatModel, object>(ChatModel, response.data.notifications.chats);
								}
								return [];
							})
						);
				})
			);
	}

	public join(chat: ChatModel) {
		this.currentChat = this.lastChat = chat;
		this.joinChat();
		this.initialMessages()
			.pipe(
				first()
			)
			.subscribe((messages: ChatMessageModel[]) => {
				if (messages) {
					this.messages.next(messages.reverse());
				} else {
					this.messages.next([]);
				}
			});
	}

	public leave(pendingMessage?: string) {
		this.pendingMessage = pendingMessage;
		this.currentChat = null;
		this.announceMe();
		this._displayMessageId = null;
		this._prevChunkEid = null;
		this._nextChunkEid = null;
		this.messages.next([]);
	}

	public restore() {
		if (this.lastChat) {
			this.join(this.lastChat);
			return this.pendingMessage;
		}
	}

	public prevMessages(done?: any) {
		this.prevChunkStream()
			.pipe(
				first()
			)
			.subscribe((chunk: ChatMessageChunkModel) => {
				if (chunk) {
					let messages = this.messages.value.concat(chunk.messages.reverse());
					this.messages.next(messages);
				}
				done && done();
			});
	}

	public nextMessages(done?: any) {
		this.nextChunkStream()
			.pipe(
				first()
			)
			.subscribe((chunk: ChatMessageChunkModel) => {
				if (chunk) {
					const messages = chunk.messages.reverse().concat(this.messages.value);
					this.messages.next(messages);
				}
				done && done();
			});
	}

	public initialMessages(): Observable<ChatMessageModel[]> {
		if (this.currentChat) {
			return this.initialChunkStream()
				.pipe(
					switchMap((chunk: ChatMessageChunkModel) => {
						if (chunk) {
							let messages = chunk.messages;
							if (this._nextChunkEid) {
								return this.nextChunkStream()
									.pipe(
										map((chunk: ChatMessageChunkModel) => {
											if (chunk) {
												messages = messages.concat(chunk.messages);
											}
											return messages;
										})
									);
							} else if (this._prevChunkEid) {
								return this.prevChunkStream()
									.pipe(
										map((chunk: ChatMessageChunkModel) => {
											if (chunk) {
												messages = chunk.messages.concat(messages);
											}
											return messages;
										})
									);
							}
							return of(chunk.messages);
						}
						return of(null);
					})
				);
		}
		return of(null);
	}


	private initialChunkStream(): Observable<ChatMessageChunkModel> {
		if (this.currentChat) {
			return this.restService
				.query({
					query: firstUnseenChunkQueryText,
					variables: {
						clientEid: this.currentChat.clientEid,
						chatTypeEid: this.currentChat.chatTypeEid,
					},
					fetchPolicy: 'network-only'
				})
				.pipe(
					map((response: any) => {
						if (response) {
							let result = plainToClass(ChatMessageChunkModel, response.data.notifications.chunk);
							this._displayMessageId = result.displayMessageId;
							this._prevChunkEid = result.prevChunkEid;
							this._nextChunkEid = result.nextChunkEid;
							return result;
						} else {
							this._displayMessageId = null;
							this._prevChunkEid = null;
							this._nextChunkEid = null;
						}
						return null;
					})
				);
		}
		return of(null);
	}

	private prevChunkStream(): Observable<ChatMessageChunkModel> {
		if (this.currentChat && this._prevChunkEid) {
			return this.restService
				.query({
					query: messagesChunkQueryText,
					variables: {
						clientEid: this.currentChat.clientEid,
						chatTypeEid: this.currentChat.chatTypeEid,
						chunkEid: this._prevChunkEid,
					},
					fetchPolicy: 'network-only'
				}, { spinner: false })
				.pipe(
					map((response: any) => {
						if (response) {
							let result = plainToClass(ChatMessageChunkModel, response.data.notifications.chunk);
							this._prevChunkEid = result.prevChunkEid;
							return result;
						}
						return null;
					})
				);
		}
		return of(null);
	}

	private nextChunkStream(): Observable<ChatMessageChunkModel> {
		if (this.currentChat && this._nextChunkEid) {
			return this.restService
				.query({
					query: messagesChunkQueryText,
					variables: {
						clientEid: this.currentChat.clientEid,
						chatTypeEid: this.currentChat.chatTypeEid,
						chunkEid: this._nextChunkEid,
					},
					fetchPolicy: 'network-only'
				}, { spinner: false })
				.pipe(
					map((response: any) => {
						if (response) {
							let result = plainToClass(ChatMessageChunkModel, response.data.notifications.chunk);
							this._nextChunkEid = result.nextChunkEid;
							return result;
						}
						return null;
					})
				);
		}
		return of(null);
	}



	private chatSocket: any = null;
	private chatClient: any = null;
	private lastReconnectTimeout = 0;
	private connect() {
		const self = this,
			authHeaderValue = self.authService.authHeaderValue();
		if (authHeaderValue) {
			const headers = {
				Authorization: authHeaderValue
			};
			self.chatSocket = new SockJS(self.configService.webEventsUrl);
			self.chatClient = Stomp.over(self.chatSocket);
			self.chatClient.connect(headers, self.chatConnected.bind(self), self.chatError.bind(self));
		} else {
			if (this.lastReconnectTimeout < 30000) {
				this.lastReconnectTimeout += 1000;
			}
			setTimeout(() => { self.connect(); }, this.lastReconnectTimeout);
		}
	}

	private chatConnected() {
		console.log('stompxxx connected');
		this.lastReconnectTimeout = 0;
		//this.announceMe();
		this.joinChat();
		this.chatClient.subscribe('/user/queue/chat.message', this.chatMessage.bind(this), {id:'chat.message'});
		this.chatClient.subscribe('/user/queue/chat.result', this.chatResult.bind(this), {id:'chat.result'});
		this.chatClient.subscribe('/user/queue/global.notif', this.chatGlobalNotif.bind(this), {id:'global.notif'});
	}

	private chatError(error: any) {
		const self = this;
		this.lastReconnectTimeout = 0;
		console.log('stompxxx error');

		self.chatClient.unsubscribe('chat.message');
		self.chatClient.unsubscribe('chat.result');
		self.chatClient.unsubscribe('global.notif');

		self.chatClient.disconnect(() => {
			console.log('stompxxx disconnected, connecting again');
			setTimeout(() => { self.connect(); }, this.lastReconnectTimeout);
			//self.connect();
		});
	}

	public sendMessageByStomp(messageText: string) {
		if (this.nextChunkEid) {
			this.chatClient.send("/app/message", {}, JSON.stringify({ content: messageText }));
		} else {
			const user = this.authService.user,
				messages = this.messages.value,
				messageCorrelationId = ++this.correlationId,
				message = plainToClass<ChatMessageModel, object>(ChatMessageModel, {
					content: messageText,
					authorEid: user.externalId,
					authorName: user.fullName,
					authorIsKeez: this.authService.isInRole("KEEZ_USER"),
					authorIsExpert: this.authService.isInRole("EXPERT") || this.authService.isInRole("HR_EXPERT"),
					datePosted: new Date(),
				});
			this.correlations[messageCorrelationId] = message;
			messages.unshift(message);
			this.messages.next(messages);

			this.chatClient.send("/app/message", {}, JSON.stringify({ correlationId: messageCorrelationId, content: messageText }));
		}
		this.updateLastMessageDelivered(this.currentChat);
		this.allMessagesSeen();
		this.allMessageAnswered();
	}

	public sendMessage(messageText: string) {
		let messageCorrelationId: number;
		let chat = this.currentChat;

		const user = this.authService.user,
			messages = this.messages.value,
			lastMsg = messages && messages.length > 0 ? messages[0] : null;

		if (!this._nextChunkEid) {
			messageCorrelationId = ++this.correlationId;

			const message = plainToClass<ChatMessageModel, object>(ChatMessageModel, {
				//messageId: messageId,
				content: messageText,
				authorEid: user.externalId,
				authorName: user.fullName,
				authorIsKeez: this.authService.isInRole("KEEZ_USER"),
				authorIsExpert: this.authService.isInRole("EXPERT") || this.authService.isInRole("HR_EXPERT"),
				datePosted: new Date(),
			});
			this.correlations[messageCorrelationId] = message;
			messages.unshift(message);
			this.messages.next(messages);
		}

		this.updateLastMessageDelivered(chat);
		this.allMessagesSeen(chat);
		this.allMessageAnswered(chat);

		this.addChatMessage(messageText, messageCorrelationId, chat).pipe(take(1)).subscribe((result) => {
			if (result) {
				try {
					const message: ChatMessageModel = this.correlations[messageCorrelationId];
					if (message) {
						message.messageId = (lastMsg != null ? lastMsg.messageId : 0) + 1,
						this.$$dataChanged.emit();
					}
				} catch (err) {
				}
			}
		});
	}

	public addChatMessage(messageContent: string, messageCorrelationId: number, chat: ChatModel): Observable<boolean> {
		return this.restService
			.mutate({
				mutation: addChatMessageMutationText,
				variables: {
					clientExternalId: chat.clientEid,
					chatTypeExternalId: chat.chatTypeEid,
					message: { 
						correlationId: messageCorrelationId == null ? null : messageCorrelationId.toString(),
						content: messageContent, 
						source: 'web'
					}
				}
			}, { spinner: false })
			.pipe(
				map((response) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	private updateLastMessageDelivered(chat: ChatModel) {
		const chats = this.chats.value;
		chat.lastMessageDelivered = new Date();
		this.sortChats(chats);
		this.chats.next(chats);
	}

	private chatMessage(payload: any) {
		try {
			if (!this._nextChunkEid) {
				const plainMessage = JSON.parse(payload.body),
					message = plainToClass(ChatMessageModel, plainMessage);

				const messages = this.messages.value;
				messages.unshift(message);
				this.messages.next(messages);
				//this.messageSeen(message.messageId);
				this.allMessagesSeen();
			}
			this.updateLastMessageDelivered(this.currentChat);
		} catch (err) {

		}
	}

	private chatResult(payload: any) {
		try {
			const plainMessage = JSON.parse(payload.body),
				message: ChatMessageModel = this.correlations[plainMessage.correlationId];
			if (message) {
				message.messageId = plainMessage.messageId;
				this.$$dataChanged.emit();
			}
		} catch (err) {
		}
	}

	private chatGlobalNotif(payload: any) {
		try {
			const plainMessage = JSON.parse(payload.body);
			if (plainMessage.eventType == 'NewNotificationForClient') {
				const chats = this.chats.value;
				if (chats && chats.length) {
					const chat = chats.find((c) => c.clientEid == plainMessage.clientEid && c.chatTypeEid == plainMessage.chatType);
					if (chat && chat != this.currentChatRoom) {
						chat.unseenMessages = (chat.unseenMessages || 0) + 1;
						this.$$dataChanged.emit();
					}
					this.updateLastMessageDelivered(chat);
				}
			}
		} catch (err) {
		}
	}

	private announceMe() {
		if (this.authService.selectedZoneCode == PredefinedUserZone.Administrator) {
			this.announce({
				clientEid: this.authService.user.selectedCompanyId,
				chatClientEid: null,
				chatType: null
			});
		} else {
			this.announce({
				clientEid: null,
				chatClientEid: null,
				chatType: null
			});
		}
	}

	private joinChat() {
		if (this.currentChat) {
			if (this.authService.selectedZoneCode == PredefinedUserZone.Administrator) {
				this.announce({
					clientEid: this.authService.user.selectedCompanyId,
					chatClientEid: this.currentChat.clientEid,
					chatType: this.currentChat.chatTypeEid,
				});
			} else {
				this.announce({
					clientEid: null,
					chatClientEid: this.currentChat.clientEid,
					chatType: this.currentChat.chatTypeEid,
				});
			}
		} else {
			this.announceMe();
		}
	}

	private announce(message: any) {
		if (this.chatClient && this.chatClient.connected) {
			this.chatClient.send("/app/announce", {}, JSON.stringify(message));
		}
	}

	public messageSeen(messageId: number) {
		const messageSeen: any = {
			messageId,
			userEid: this.authService.user.externalId,
			clientEid: this.currentChat.clientEid,
			chatType: this.currentChat.chatTypeEid,
		}
		this.chatClient.send("/app/message-seen", {}, JSON.stringify(messageSeen));
		if (this._displayMessageId) {
			const messages = this.messages.value,
				unseenMessages = messages.filter((m) => m.messageId > this._displayMessageId && m.messageId <= messageId).length;
			this.currentChat.unseenMessages = Math.max(this.currentChat.unseenMessages - unseenMessages, 0);
			if (messages.find((m: any) => m.messageId > messageId)) {
				this._displayMessageId = messageId;
			} else {
				this._displayMessageId = null;
			}
		}
	}

	public allMessagesSeen(chat: ChatModel = null) {
		let cChat = chat == null ? this.currentChat : chat;

		if (cChat) {
			const messageSeen: any = {
				messageId: -1,
				userEid: this.authService.user.externalId,
				clientEid: cChat.clientEid,
				chatType: cChat.chatTypeEid,
			}
			this.chatClient.send("/app/message-seen", {}, JSON.stringify(messageSeen));
			this._displayMessageId = null;
			cChat.unseenMessages = 0;
			this.$$dataChanged.emit();
		}
	}

	public allMessageAnswered(chat: ChatModel = null) {
		let cChat = chat == null ? this.currentChat : chat;

		if (cChat) {
			cChat.unansweredMessages = 0;
			cChat.unseenMessages = 0;
			cChat.firstUnansweredMessage = null;
			this.$$dataChanged.emit();
		}
	}

	public moveMessageStream(messageId: number, destChatTypeEid: string) {
		return this.restService
			.mutate({
				mutation: moveMessageMutationText,
				variables: {
					clientEid: this.currentChatRoom.clientEid,
					chatTypeEid: this.currentChat.chatTypeEid,
					messageId,
					destChatTypeEid,
				}
			})
			.pipe(
				map((res: any) => {
					if (res) {
						return true;
					}
					return false;
				})
			);
	}

	public markClientChatUrgent(chatInput: ClientChatInputModel): Observable<boolean> {
		return this.restService
			.mutate({
				mutation: markClientChatUrgent,
				variables: {
					clientChatInput: classToPlain(chatInput, { excludePrefixes: ["__"] })
				}
			}, { spinner: false })
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public markClientChatImportant(chatInput: ClientChatInputModel): Observable<boolean> {
		return this.restService
			.mutate({
				mutation: keepClientChatImportant,
				variables: {
					clientChatInput: classToPlain(chatInput, { excludePrefixes: ["__"] })
				}
			}, { spinner: false })
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public markClientChatAnswered(clientChatCommentInput: ClientChatCommentInputModel): Observable<boolean> {
		return this.restService
			.mutate({
				mutation: markClientChatAnswered,
				variables: {
					clientChatCommentInput: classToPlain(clientChatCommentInput, { excludePrefixes: ["__"] })
				}
			}, { spinner: false })
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public async joinVideoChatMeeting(videoChatEid: string): Promise<string> {
		let mutation = new JoinMeetingGQL(this.restService);
		let result = await mutation.mutate({
			meetingEid: videoChatEid
		}).toPromise();

		if (result) {
			return result.data?.notifications?.joinMeetingUsingPOST?.tokenEid
		}

		return null;
	}

	public async getMeetingSlots(clientEid: string, startDate?: number, endDate?: number): Promise<MeetingSlotsListModel> {
		let query = new GetMeetingSlotsGQL(this.restService);
		let queryData = await query.fetch(
			{
				clientEid: clientEid,
				startDate: startDate,
				endDate: endDate
			}
		).toPromise();

		if (queryData != null) {
			let result = plainToClass(MeetingSlotsListModel, queryData.data.notifications.getMeetingSlotsUsingGET);
			result.expertList = plainToClass<MeetingSlotsExpertListModel, object>(MeetingSlotsExpertListModel, result.expertList || []);
			result.expertList.forEach((expert) => {
				expert.timeSlots = plainToClass<MeetingTimeSlotsModel, object>(MeetingTimeSlotsModel, expert.timeSlots || []);
			});

			return result;
		}

		return null;
	}

	public async requestMeeting(clientEid: string, meetingRequestModel: MeetingRequestModel): Promise<string> {
		let mutation = new RequestMeetingGQL(this.restService);
		
		let mutationData: Notifications_MeetingRequestRepresentationInput = {};
		mutationData.meetingRequestEid = meetingRequestModel.meetingRequestEid;
		mutationData.chatTypeEid = meetingRequestModel.chatTypeEid;
		mutationData.date = meetingRequestModel.date;
		mutationData.fromTime = meetingRequestModel.fromTime;
		mutationData.comment = meetingRequestModel.comment;

		let result = await mutation.mutate({
			clientEid: clientEid,
			data: mutationData
		}, { error: true } as KMutationOptions).toPromise();

        return result?.data?.notifications?.requestMeetingUsingPUT?.meetingRequestEid;
	}

	public async getMeetingRequestData(clientEid: string, meetingRequestEid: string): Promise<MeetingRequestDataModel> {
		let query = new GetMeetingRequestGQL(this.restService);
		let queryData = await query.fetch(
			{
				clientEid: clientEid,
				meetingRequestEid: meetingRequestEid,
			}
		).toPromise();

		if (queryData != null) {
			let result = plainToClass(MeetingRequestDataModel, queryData.data.notifications.getMeetingRequestUsingGET);
			result.history = plainToClass<MeetingRequestDataHistoryModel, object>(MeetingRequestDataHistoryModel, result.history || []);

			return result;
		}

		return null;
	}

	public async acceptMeetingRequest(clientEid: string, meetingRequestEid: string): Promise<string> {
		let mutation = new AcceptMeetingGQL(this.restService);

		let mutationData: Notifications_MeetingRequestRepresentationInput = {};
		mutationData.meetingRequestEid = meetingRequestEid;

		let result = await mutation.mutate({
			clientEid: clientEid,
			data: mutationData
		}, { error: true } as KMutationOptions).toPromise();

        return result?.data?.notifications?.acceptMeetingUsingPOST?.meetingRequestEid;
	}

	public async cancelMeetingRequest(clientEid: string, meetingRequestEid: string) {
		let mutation = new CancelMeetingGQL(this.restService);

		let result = await mutation.mutate({
			clientEid: clientEid,
			meetingRequestEid: meetingRequestEid,
		}, { error: true } as KMutationOptions).toPromise();

        return result?.data?.notifications?.cancelMeetingUsingDELETE?.Message;
	}

	public async proposeNewMeeting(clientEid: string, meetingRequestModel: MeetingRequestModel): Promise<string> {
		let mutation = new ProposeNewMeetingScheduleGQL(this.restService);
		
		let mutationData: Notifications_MeetingRequestRepresentationInput = {};
		mutationData.meetingRequestEid = meetingRequestModel.meetingRequestEid;
		mutationData.chatTypeEid = meetingRequestModel.chatTypeEid;
		mutationData.date = meetingRequestModel.date;
		mutationData.fromTime = meetingRequestModel.fromTime;
		mutationData.comment = meetingRequestModel.comment;

		let result = await mutation.mutate({
			clientEid: clientEid,
			meetingRequestEid: meetingRequestModel.meetingRequestEid,
			data: mutationData
		}, { error: true } as KMutationOptions).toPromise();

        return result?.data?.notifications?.proposeNewMeetingScheduleUsingPATCH?.meetingRequestEid;
	}

	public async getMainClientExpertInfo(clientEid: string): Promise<ExpertInfoPersonModel> {
		let query = new GetMainClientExpertInfoGQL(this.restService);
		let queryData = await query.fetch(
			{
				clientEid: clientEid
			}
		).toPromise();

		if (queryData != null) {
			let result = plainToClass(ExpertInfoPersonModel, queryData.data.accounts.mainClientExpertUsingGET.person);
			return result;
		}

		return null;
	}
}

