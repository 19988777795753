import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SalienteLibrarySharedModule } from "@saliente/library";
import { ClientFormsService } from "../client-forms.service";
import { DuPFA2024FormComponent } from "./dupfa2024-form.component";
import { DuPFA2024Component } from "./du-pfa-2024.component";
import { ChirieDuPFA2024Component } from "./chirie-du-pfa-2024.component";
import { ActivitateIndependentaDuPFA2024Component } from "./activitate-independenta-du-pfa-2024.component";

@NgModule({
    imports: [
		FormsModule, SalienteLibrarySharedModule
	],
	providers: [
		ClientFormsService
	],
	declarations: [
		DuPFA2024FormComponent, DuPFA2024Component, ChirieDuPFA2024Component, ActivitateIndependentaDuPFA2024Component
	],
	exports: [
		DuPFA2024FormComponent, 
	]
})
export class DuPFA2024SharedModule { }