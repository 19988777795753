import { Component, OnInit } from '@angular/core';

import { IMessageBoxComponent, Message, MessageBoxService } from '../../../services';

@Component({
	selector: 'kee-messagebox',
	templateUrl: 'messagebox.component.html',
	styleUrls: ['messagebox.component.scss']
})

export class KeeMessageBoxComponent implements OnInit, IMessageBoxComponent {
	public message: Message;
	public html: boolean = false;
	public opened: boolean = false;

	public width: number = 450;
	public height: number;
	public minWidth: number = 250;
	public maxHeight: number;

	constructor(private messageBoxService: MessageBoxService) {
		this.messageBoxService.register(this);
	}

	public ngOnInit() { }

	public show(message: Message) {
		this.message = message;
		this.html = message.html || false;
		this.opened = true;
		this.minWidth = message.minWidth || 250;
		this.width = message.width || 450;
		this.height = message.height;
		this.maxHeight = message.maxHeight;
	}

	public close(result: string) {
		this.opened = false;
		if (this.message.callback) {
			this.message.callback(result);
		}
	}
}
