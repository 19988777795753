import { Type, Expose, Exclude } from 'class-transformer';

import { DocumentFileBaseModel, } from './documents-base.models';

export class DocumentsTypesProvider {
	static date() {
		return Date;
	}
}
export class DocumentCommentModel {
	public comment: string;
	@Type(DocumentsTypesProvider.date)
	public dateTime: Date;

	static typeProvider() {
		return DocumentCommentModel;
	}
}
export class DocumentFileModel extends DocumentFileBaseModel {
	@Expose({ name: 'eid' })
	public id: string;

	@Type(DocumentCommentModel.typeProvider)
	public comments?: DocumentCommentModel[] = [];

	@Exclude()
	get isInvoice(): boolean {
		return !this.metadataEx || this.metadataEx.sourceModule == 'InvoiceModule';
	}

	@Exclude()
	get isEFacturaSigned(): boolean {
		return this.metadataEx && this.metadataEx.eFacturaSigned == "true";
	}

	@Exclude() 
	get isEFacturaModule(): boolean {
		return this.metadataEx && this.metadataEx.source == 'EFacturaModule';
	}

	@Exclude() 
	get isEFacturaOutboxModule(): boolean {
		return this.metadataEx && this.metadataEx.source == 'EFacturaOutboxModule';
	}

	@Exclude()
	get documentIsProtected(): boolean {
		return this.metadataEx && (this.metadataEx.isKeezContractualInvoice == 'true' || this.metadataEx.isProtected == 'true');
	}

	public copy(src: DocumentFileModel) {
		super.copy(src);
	}
}

export class PersonForAccountingModel {
	public employeeExternalId: string;
	public isActive: boolean;
	public firstName: string;
	public lastName: string;
	@Exclude({ toPlainOnly: true })
	public ciDocumentNumber: string;
	public employeePIN: string;
	@Exclude({ toPlainOnly: true })
	public fullName: string;
}