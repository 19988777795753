import { Injectable } from '@angular/core';

import { plainToClass } from 'class-transformer';

import { PredefinedUserZone, } from '@saliente/common-auth';
import { AppMenuService, AppMenuModel, } from '@saliente/common-appmenu';

import { CLIENTS_ROUTES } from '@saliente/clients';
import { HR_EXPERT_ROUTES } from '@saliente/hrexpert';

import { EXPERT_ROUTES } from '../expert.routes.constants';
import { PAYROLL_ROUTES } from 'src/app/payroll/payroll.routes.constants';
import { STOCK_ROUTES } from 'src/app/stocks/stock.routes.constants';
import { CLIENT_FORMS_ROUTES } from 'src/app/client-forms';
import { ESHOP_ROUTES } from 'src/app/eshop';
import { PARAMETERS_ROUTES } from 'src/app/parameters';

@Injectable()
export class MenuService {
	// https://www.jq22.com/demo/Materialize201705231801/css-icons.html
	constructor(private appMenuService: AppMenuService) {
		/** MAIN MENUS */
		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			accessRole: 'CONNECT_ACCOUNTING_ACCESS',
			zone: PredefinedUserZone.Expert,
			text: 'Connect',
			isChat: true,
			icon: 'mdi-communication-chat',
			link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.CHAT],
		}));

		
		/** PORTOFOLIO */
		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			zone: PredefinedUserZone.Expert,
			text: 'Portofoliu',
      		isPortofolioMenu: true,
			icon: 'mdi-content-content-copy',
			canExpand: true,
			alwaysExpanded: false,
			childrenMenu: [
				plainToClass(AppMenuModel, {
					home: true,
					//accessRole: '',
					zone: PredefinedUserZone.Expert,
					text: 'Firmele mele',
					icon: 'mdi-maps-navigation', //AM. 08.11.2021. task 5094. old: 'mdi-action-3d-rotation',
					class: 'child',
					link: [EXPERT_ROUTES.ROOT, EXPERT_ROUTES.DASHBOARD]
				}),
				plainToClass(AppMenuModel, {
					home: false,
					//accessRole: 'PREVIEW',
					zone: PredefinedUserZone.Expert,
					text: 'Declarațiile mele',
					icon: 'mdi-notification-event-available',
					class: 'child',
					link: [EXPERT_ROUTES.ROOT, EXPERT_ROUTES.STATUTORY_REPORTS_DASHBOARD]
				}),
				plainToClass(AppMenuModel, {
					//accessRole: '',
					zone: PredefinedUserZone.Expert,
					text: 'Bilanțurile mele',
					icon: 'mdi-av-new-releases',
					class: 'child',
					link: [EXPERT_ROUTES.ROOT, EXPERT_ROUTES.BALANCE_SHEET_DASHBOARD]
				}),
				plainToClass(AppMenuModel, {
					//accessRole: '',
					zone: PredefinedUserZone.Expert,
					text: 'Fișele ROL',
					icon: 'mdi-action-dns',
					class: 'child',
					link: [EXPERT_ROUTES.ROOT, EXPERT_ROUTES.FISA_ROL_SUMAR]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					accessRole: 'TAKEOVER_VIEW',
					text: 'Preluările mele',
          			isTakeover: true,
					icon: 'mdi-communication-call-merge mdi-rotate-180',
					class: 'child',
					link: [EXPERT_ROUTES.ROOT, EXPERT_ROUTES.TAKEOVER_DASHBOARD]
				})
			]
		}));

	
		/** ACCOUNTING */
		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			zone: PredefinedUserZone.Expert,
			text: 'Contabilitate',
			//class: 'grey lighten-2',
			icon: 'mdi-content-content-copy',
			canExpand: true,
			alwaysExpanded: false,
			isAtFirstExpanded: true,
			isAccountingMenu: true,
			childrenMenu: [
				plainToClass(AppMenuModel, {
					accessRole: 'DASHBOARD_VIEW',
					zone: PredefinedUserZone.Expert,
					text: 'Dashboard client',
					icon: 'mdi-action-dashboard', //AM. 08.11.2021. task 5094. old: 'mdi-action-trending-up',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT]
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'ANAF_DATA_VIEW',
					zone: PredefinedUserZone.Expert,
					text: 'Anaf',
			  		isAnafComm: true,
					icon: 'mdi-action-settings-input-antenna',
					class: 'child',
					link: [EXPERT_ROUTES.ROOT, EXPERT_ROUTES.ANAF_MESSAGES]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					text: 'Sumar',
					icon: 'mdi-maps-my-location', //AM. 08.11.2021. task 5094. old: 'mdi-communication-clear-all',
					class: 'child',
					link: [EXPERT_ROUTES.ROOT, EXPERT_ROUTES.DOCUMENTS_SUMMARY]
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'ACCNT_DOCUMENTS_VIEW_OWN;ACCNT_DOCUMENTS_VIEW',
					zone: PredefinedUserZone.Expert,
					text: 'Documente',
					icon: 'mdi-content-content-copy',
					class: 'child',
					isFinancialDocuments: true,
					link: [EXPERT_ROUTES.ROOT, EXPERT_ROUTES.DOCUMENTS]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					text: 'Note contabile',
					icon: 'mdi-action-list',
					class: 'child',
					link: [EXPERT_ROUTES.ROOT, EXPERT_ROUTES.ACC_NOTES]
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'IMPORT_OPS',
					zone: PredefinedUserZone.Expert,
					text: 'Importuri',
					isImportMenu: true,
					icon: 'mdi-action-note-add',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.IMPORT],
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					text: 'Clarificare',
					isClarificationMenu: true,
					icon: 'mdi-action-account-balance',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.CLARIFICATION]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					text: 'Reconciliere',
					isReconciliationMenu: true,
					icon: 'mdi-communication-call-merge mdi-rotate-90',
					class: 'child',
					link: [EXPERT_ROUTES.ROOT, EXPERT_ROUTES.PAYMENT_ALLOCATION]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					text: 'Clarificare card',
					isCardClarificationMenu: true,
					icon: 'mdi-action-credit-card',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.CARD_CLARIFICATION]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					accessRole: 'DASHBOARD_VIEW',
					text: 'Documente lipsă',
					isMissingDocsMenu: true,
					icon: 'mdi-communication-no-sim',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.MISSING_DOCUMENTS]
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'DASHBOARD_VIEW',
					zone: PredefinedUserZone.Expert,
					text: 'Rapoarte',
					icon: 'mdi-action-find-in-page',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.ACCOUNTING_REPORTS]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					text: 'Închidere',
					icon: 'mdi-action-verified-user',
					class: 'child',
					link: [EXPERT_ROUTES.ROOT, EXPERT_ROUTES.CLOSING_AUDIT]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					text: 'Declarații',
					icon: 'mdi-notification-event-available',
					class: 'child',
					link: [EXPERT_ROUTES.ROOT, EXPERT_ROUTES.STATUTORY_REPORTS]
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'ACCNT_REPORTS_VIEW',
					zone: PredefinedUserZone.Expert,
					text: 'Rezultate lunare',
					icon: 'mdi-content-archive',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.ACCOUNTING_HOME]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					text: 'Bilanțuri',
			  		isBalanceSheet: true,
					icon: 'mdi-av-new-releases',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.BALANCE_SHEET]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					text: 'Chestionare',
					icon: 'mdi-action-note-add',
					class: 'child',
					link: [CLIENT_FORMS_ROUTES.ROOT, CLIENT_FORMS_ROUTES.CLIENT_FORMS]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					text: 'Declarații unice',
					icon: 'mdi-action-note-add',
					class: 'child',
					link: [CLIENT_FORMS_ROUTES.ROOT, CLIENT_FORMS_ROUTES.DU_PFA_FORMS]
				}),
			]
		}));

		/** PAYROLL */
		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			zone: PredefinedUserZone.Expert,
			text: 'Salarizare',
			//class: 'grey lighten-2',
			icon: 'mdi-communication-vpn-key',
			canExpand: true,
			isPayrollMainMenu: true,
			childrenMenu: [ 
				plainToClass(AppMenuModel, {
					accessRole: 'EMPLOYEE_VIEW',
					zone: PredefinedUserZone.Expert,
					text: 'Salariați',
					isSalariatiMenu: true,
					icon: 'mdi-social-people',
					class: 'child',
					//link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.EMPLOYEES],
					link: [PAYROLL_ROUTES.ROOT, PAYROLL_ROUTES.EMPLOYEES],
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'PAYROLL_TIMESHEET_VIEW',
					zone: PredefinedUserZone.Expert,
					text: 'Pontaj & Stat',
					icon: 'mdi-av-timer',
					class: 'child',
					leafLink: true,
					link: [PAYROLL_ROUTES.ROOT, PAYROLL_ROUTES.CALCULATION_ROOT, 'vacation'],
					//link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.TIMESHEET],
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					accessRole: 'EMPLOYEE_VIEW',
					text: 'Concedii medicale',
					icon: 'mdi-maps-local-hospital',
					class: 'child',
					link: [PAYROLL_ROUTES.ROOT, PAYROLL_ROUTES.SICK_LEAVE],
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'PAYROLL_DOCUMENTS_VIEW',
					zone: PredefinedUserZone.Expert,
					text: 'Documente',
					icon: 'mdi-content-content-copy',
					class: 'child',
					leafLink: true,
					//disabled: true,
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.PAYROLL_DOCUMENTS],
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					text: 'Declarații',
					icon: 'mdi-notification-event-available',
					class: 'child',
					link: [HR_EXPERT_ROUTES.ROOT, HR_EXPERT_ROUTES.STATUTORY_REPORTS]
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'PAYROLL_REPORTS_VIEW',
					zone: PredefinedUserZone.Expert,
					text: 'Rezultate lunare',
					icon: 'mdi-content-archive', //'mdi-file-folder-shared',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.PAYROLL_HOME]
				})
			]
		}));


		/** INVOICING */
		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			zone: PredefinedUserZone.Expert,
			text: 'Facturare',
			isInvoiceMenu: true,
			//class: 'grey lighten-2',
			icon: 'mdi-communication-vpn-key',
			canExpand: true,
			childrenMenu: [ 
				plainToClass(AppMenuModel, {
					accessRole: 'INVOICES_VIEW_OWN;INVOICES_VIEW',
					zone: PredefinedUserZone.Expert,
					text: 'Facturi',
					icon: 'mdi-editor-attach-money',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.INVOICES]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					text: 'e-Factura',
					isEFacturaMenu: true,
					accessRole: 'EFACTURA',
					icon: 'mdi-e-letter ml-1 mr-4',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.E_FACTURA, 'inbox']
				}),
				/*plainToClass(AppMenuModel, {
					accessRole: 'INVOICES_VIEW_OWN;INVOICES_VIEW',
					zone: PredefinedUserZone.Expert,
					text: 'Articole',
					icon: 'mdi-content-content-paste',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.ITEMS]
				})*/
			]
		}));

		/** STOCKS */
		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			zone: PredefinedUserZone.Expert,
			text: 'Stocuri',
			//icon: 'mdi-communication-vpn-key',
			canExpand: true,
			childrenMenu: [
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					accessRole: 'INVENTORY_EVENT_VIEW',
					text: 'Intrări',
					icon: 'mdi-editor-vertical-align-top mdi-rotate-90',
					class: 'child',
					link: [STOCK_ROUTES.ROOT, STOCK_ROUTES.INVENTORY_MANAGEMENT, STOCK_ROUTES.INPUT_EVENTS]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					accessRole: 'INVENTORY_EVENT_VIEW',
					text: 'Ieșiri',
					icon: 'mdi-editor-vertical-align-bottom mdi-rotate-90',
					class: 'child',
					link: [STOCK_ROUTES.ROOT, STOCK_ROUTES.INVENTORY_MANAGEMENT, STOCK_ROUTES.OUTPUT_EVENTS]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					accessRole: 'INVENTORY_EVENT_VIEW',
					text: 'Inventare',
					icon: 'mdi-editor-vertical-align-center mdi-rotate-90',
					class: 'child',
					link: [STOCK_ROUTES.ROOT, STOCK_ROUTES.INVENTORY_MANAGEMENT, STOCK_ROUTES.INVENTORY]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Administrator,
					accessRole: 'INVENTORY_EVENT_VIEW',
					text: 'Rapoarte',
					icon: 'mdi-action-find-in-page',
					class: 'child',
					link: [STOCK_ROUTES.ROOT, STOCK_ROUTES.INVENTORY_REPORTS]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					text: 'Închidere și descărcare',
					accessRole: 'INVENTORY_EVENT_VIEW',
					icon: 'mdi-notification-event-available',
					class: 'child',
					link: [STOCK_ROUTES.ROOT, STOCK_ROUTES.INVENTORY_PERIODS]
				}),
			]
		}));
		
		/** ESHOP */
		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			zone: PredefinedUserZone.Expert,
			text: 'Magazin online',
			icon: 'mdi-action-shopping-cart',
			canExpand: true,
			childrenMenu: [
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					text: 'Servicii',
					icon: 'mdi-action-store',
					accessRole: 'ESHOP_ORDER_VIEW',
					class: 'child',
					link: [ESHOP_ROUTES.ROOT, ESHOP_ROUTES.PRODUCTS],
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					text: 'Comenzile mele',
					icon: 'mdi-action-shopping-cart',
					accessRole: 'ESHOP_ORDER_VIEW',
					class: 'child',
					link: [ESHOP_ROUTES.ROOT, ESHOP_ROUTES.ORDERS],
				}),
			]
		}));
		
		/** CONFIG */
		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			zone: PredefinedUserZone.Expert,
			text: 'Administrare',
			//class: 'grey lighten-2',
			icon: 'mdi-action-settings',
			canExpand: true,
			childrenMenu: [ 
				plainToClass(AppMenuModel, {
					accessRole: 'CONFIG_COMPANY_VIEW',
					zone: PredefinedUserZone.Expert,
					text: 'Date firmă',
					icon: 'mdi-social-location-city',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.COMPANY],
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					text: 'Contract Keez',
					icon: 'mdi-maps-map',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.CLIENT_CONTRACT, 'contract'],
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'COMPANY_PARAM_VIEW',
					zone: PredefinedUserZone.Expert,
					text: 'Configurări',
					icon: 'mdi-action-settings-applications',
					class: 'child',
					leafLink: true,
					link: [PARAMETERS_ROUTES.ROOT_1, PARAMETERS_ROUTES.COMPANY, 'generic'],
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'USERS_VIEW',
					zone: PredefinedUserZone.Expert,
					text: 'Utilizatori',
					icon: 'mdi-action-account-circle',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.USERS],
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'TAKEOVER_VIEW',
					zone: PredefinedUserZone.Expert,
					text: 'Preluare',
					icon: 'mdi-communication-call-merge mdi-rotate-180',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.TAKEOVER],
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					accessRole: 'ZEUS_OPS',
					visibleRole: 'ZEUS_OPS',
					text: 'Operațiuni',
					icon: 'mdi-action-find-in-page',
					class: 'child',
					link: [EXPERT_ROUTES.ROOT, EXPERT_ROUTES.OPERATIONAL_DASHBOARD]
				})
			]
		}));

		/** DICTIONARIES */
		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			zone: PredefinedUserZone.Expert,
			text: 'Dicționare',
			icon: 'mdi-action-settings',
			canExpand: true,
			childrenMenu: [
				plainToClass(AppMenuModel, {
					accessRole: 'INVOICES_VIEW_OWN;INVOICES_VIEW',
					zone: PredefinedUserZone.Expert,
					text: 'Parteneri',
					icon: 'mdi-communication-business',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.CLIENTPARTNERS]
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'INVOICES_VIEW_OWN;INVOICES_VIEW',
					zone: PredefinedUserZone.Expert,
					text: 'Articole',
					icon: 'mdi-content-content-paste',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.ITEMS]
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'CARS_VIEW',
					zone: PredefinedUserZone.Expert,
					text: 'Autovehicule',
					icon: 'mdi-maps-directions-car',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.MISC, CLIENTS_ROUTES.VEHICLES],
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'CONFIG_COMPANY_EDIT',
					zone: PredefinedUserZone.Expert,
					text: 'Conturi bancare',
					icon: 'mdi-action-account-balance',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.COMPANY_BANK_ACCOUNTS],
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'CONFIG_COMPANY_EDIT',
					zone: PredefinedUserZone.Expert,
					text: 'Carduri angajați',
					icon: 'mdi-action-credit-card',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.COMPANY_EMPLOYEES_CARDS],
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Expert,
					text: 'Formulare',
					icon: 'mdi-action-note-add',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.MISC, CLIENTS_ROUTES.TEMPLATES],
				}),
			]
		}));
	}
}
