
import { throwError as observableThrowError, Observable, of, } from 'rxjs';
import { catchError, } from 'rxjs/operators';

import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, } from '@angular/common/http';



import { COMMON_ROUTES } from '../common.routes.constants';

const accountingPartnerCookieName = '##accounting_partner##';

@Injectable()
export class ConfigService {
	private config: any;

	static current: any = {};

	constructor(private injector: Injector,) {
		ConfigService.current = this;
	}

	public load() {
		return new Promise((resolve, reject) => {
			this.http
				.get('/config')
				.pipe(
					catchError((error: any): any => {
						resolve(true);
						return observableThrowError(error.error || 'Server error');
					})
				).subscribe((config) => {
					this.config = config;
					resolve(true);
				}, (error) => {
					this.config = {};
					this.page500();
				});
		});
	}

	public get router(): Router { //this creates router property on your service.
		return this.injector.get(Router);
	}

	public get http(): HttpClient { //this creates http property on your service.
		return this.injector.get(HttpClient);
	}

	get environment(): string {
		return this.config.environment;
	}

	public getAuthUrl(): Observable<string> {
		return of(this.config.auth);
	}

	public getAuthLinkUrl() {
		return of(this.config.authUrl);
	}

	public authLinkUrl() {
		return this.config.authUrl;
	}

	get procsUrl() {
		return this.config.procs;
	}

	get documentsUrl() {
		return '';
	}

	public getDocumentsUrl(): Observable<string> {
		return of('');
	}

	get documentsUploadUrl() {
		return this.config.documents;
	}

	public getDocumentsUploadUrl(): Observable<string> {
		return of(this.config.documents);
	}

	get accountsDownloadUrl() {
		return this.config.accounts;
	}

	public getAccountsDownloadUrl(): Observable<string> {
		return of(this.config.accounts);
	}

	public getPayrollUrl(): Observable<string> {
		return of(this.config.payroll);
	}

	get payrollUrl() {
		return this.config.payroll;
	}

	public getAccountsUrl(): Observable<string> {
		return of(this.config.accounts);
	}

	get accountsUrl() {
		return this.config.accounts;
	}

	get documentsDownloadUrl() {
		return this.config.documents;
	}

	public getDocumentsDownloadUrl(): Observable<string> {
		return of(this.config.documents);
	}

	get reportsDownloadUrl() {
		return this.config.reports;
	}

	public getReportsDownloadUrl(): Observable<string> {
		return of(this.config.reports);
	}

	get subsDownloadUrl() {
		return this.config.subs;
	}

	public getSubsDownloadUrl(): Observable<string> {
		return of(this.config.subs);
	}

	get importsDownloadUrl() {
		return this.config.imports;
	}

	public getImportsDownloadUrl(): Observable<string> {
		return of(this.config.imports);
	}

	public getBankingUrl(): Observable<string> {
		return of(this.config.banking);
	}

	get webEventsUrl() {
		return this.config['web-events'];
	}


	get clientContractModel() {
		return this.config.clientContractModel;
	}

	get templatesEid() {
		return this.config.templatesEid;
	}

	get postingJournalsEid() {
		return 'JournalTypes';//this.config.postingJournalsEid;
	}

	get postingAccountsEid() {
		return 'ChartOfAccounts';//this.config.postingAccountsEid;
	}

	get vatOptionsEid() {
		return 'VATOption';//this.config.vatOptionsEid;
	}

	get vatQuotasEid() {
		return 'VATQuota';//this.config.vatQuotasEid;
	}

	get deductibilityEid() {
		return 'DeductibilityName';//this.config.deductibilityEid;
	}

	get payU() {
		return this.config.payU;
	}

	get localCurrencyCode() {
		return this.config.localCurrencyCode || 'RON';
	}

	get localCountryCode() {
		return this.config.localCountryCode || 'RO';
	}

	get localCountryName() {
		return this.config.localCountryName || 'Romania';
	}

	get localRegionCode() {
		return this.config.localRegionCode || 'RO';
	}

	get useDemoAccount() {
		const queryParams: any = new URLSearchParams(window.location.search);
		let result = false;
		queryParams.forEach((value: string, key: string) => {
			if (key.toLowerCase() == 'account') {
				result = (value == 'demo');
			}
		});
		return result;
	}

	private _accountingPartner: string;
	get accountingPartner() {
		if (!this._accountingPartner) {
			this._accountingPartner = sessionStorage.getItem(accountingPartnerCookieName);

			if (!this._accountingPartner) {
				const queryParams: any = new URLSearchParams(window.location.search);
				queryParams.forEach((value: string, key: string) => {
					if (key.toLowerCase() == 'accountingpartner') {
						this._accountingPartner = value;
					}
				});
			}
			this.updateAccountingPartnerCookie();
		}
		return this._accountingPartner;
	}

	private _campaign: string;
	get campaign() {
		if (!this._campaign) {
			const queryParams: any = new URLSearchParams(window.location.search);
			queryParams.forEach((value: string, key: string) => {
				if (key.toLowerCase() == 'campaign') {
					this._campaign = value;
				}
			});
		}
		return this._campaign;
	}

	set accountingPartner(value: string) {
		this._accountingPartner = value;
		this.updateAccountingPartnerCookie();
	}

	private updateAccountingPartnerCookie() {
		if (this._accountingPartner) {
			sessionStorage.setItem(accountingPartnerCookieName, this._accountingPartner);
			//document.cookie = `${accountingPartnerCookieName}=${this._accountingPartner}`;
		} else {
			sessionStorage.removeItem(accountingPartnerCookieName);
			//document.cookie = `${accountingPartnerCookieName}=${this._accountingPartner}; expires=Thu, 18 Dec 2013 12:00:00 UTC`;
		}
	}

	get themeFolder() {
		return this.accountingPartner || 'default';
	}

	public page500() {
		this.router.navigate([COMMON_ROUTES.ERROR_500]);
	}

	public pageUnixTimeError() {
		this.router.navigate([COMMON_ROUTES.ERROR_UNIX_TIME]);
	}

	public static appInitializerFactory(config: ConfigService): () => void {
		const result = () => {
			return config.load();
		};
		return result;
	}
}
